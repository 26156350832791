import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n"; // Импортируйте i18n для инициализации
import CookieBanner from "./components/CookieBanner";

// Добавление классов к body
document.body.className = "bg-[#191C1F] font-tt-firs-neue text-white";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <App />
    <CookieBanner />
  </Router>
);
